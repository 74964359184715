import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import ForasTag from "./components/ForasTag.js"
import ProfileImage from "./components/ProfileImage.js"
import { Rating } from "react-simple-star-rating"
import Loading from "./components/Loading.js"
import DownloadLink from "./components/DownloadLink.js"
import AsyncSelect from 'react-select/async'
import { Modal } from "react-bootstrap"
import { useEffect, useMemo, useRef, useState } from "react"
import { useUser } from "../hooks/useUser.js"
import axios from "axios"
import { calculateTimeAgo } from "../utils/JobUtils.js"
import PdfLogo from './stylesheets/imgs/pdflogo.svg'
import { maxJobFileSize } from "../config.js"

function JobFile({ file, index, initialStatus, curUser, jobId, setFiles, editable = false, downloadable = false }) {
    const [status, setStatus] = useState(initialStatus)
    const [progress, setProgress] = useState()
    const [message, setMessage] = useState("")

    const preventUpload = useRef(status === 'uploaded')
    const fileID = useRef(file?._id)

    async function upload() {

        if (!editable) return;

        // Checking file size
        if (file.size > maxJobFileSize) {
            setMessage(`File too large. Max size is ${maxJobFileSize / (1024 * 1024)}MB`)
            return
        }

        // TODO: Check file type if needed

        const formData = new FormData();
        formData.append('file', file);
        formData.append('user', curUser.id);
        formData.append('job', jobId);

        setStatus('uploading')
        preventUpload.current = true
        axios.post('/jobs/files/upload', formData, { onUploadProgress: (e) => setProgress(e.progress) })
            .then(result => {
                fileID.current = result.data._id
                setStatus('uploaded')
                setMessage('Success')
            })
            .catch(error => {
                preventUpload.current = false;
                console.error(error)
                setMessage(error.response?.data || error.message)
                setStatus('error')
            })
    }

    async function handleDelete() {
        const prevStatus = status

        axios.delete('/jobs/files/delete', { data: { user: curUser.id, job: jobId, file: fileID.current } })
            .then(() => {
                setFiles(prev => {
                    const updated = [...prev]
                    updated.splice(index, 1)
                    return updated
                })
            })
            .catch(error => {
                // If it wasn't already uploaded, delete it regardless of result
                if (prevStatus !== 'uploaded') {
                    setFiles(prev => {
                        const updated = [...prev]
                        updated.splice(index, 1)
                        return updated
                    })
                }

                console.error(error)
                setMessage(error.response?.data || error.message)
                setStatus('error')
            })
    }

    useEffect(() => {
        if (preventUpload.current) {
            return;
        }
        upload()
    }, [])

    return (
        <div className='fileInfo' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
            {editable && <h4 onClick={handleDelete} style={{ color: 'red', cursor: "pointer" }}>X</h4>}
            <DownloadLink
                disabled={status !== 'uploaded' || !downloadable}
                downloadName={file.name}
                requestBody={{ user: curUser.id, file: fileID.current, job: jobId }}
                url={'jobs/files/download'}
                onDownloadProgress={(e) => setProgress(e.progress)}
            >
                <div>
                    <img src={PdfLogo} alt='PDF Logo' style={{ width: 25 }} />
                    <p style={{ fontSize: 10 }}>{file.name}</p>
                </div>
            </DownloadLink>
            {progress && <h4>{(progress * 100).toPrecision(3)}%</h4>}
            {status === 'uploading' && <Loading size={30} />}
            <br />
            <h4 style={{ color: status === 'error' ? 'red' : 'green' }}>{message}</h4>
        </div>
    )
}

export default function ActiveForsa() {
    // Job and page states
    const [job, setJob] = useState(undefined)
    const [owner, setOwner] = useState(undefined)

    // Application states
    const [sessionUploadedFiles, setSessionUploadedFiles] = useState([])
    const [previouslyUploadedFiles, setPreviouslyUploadedFiles] = useState([])
    const [teamMembers, setTeamMembers] = useState([])
    const [loading, setLoading] = useState(false)

    // Other hooks
    const curUser = useUser()
    const navigate = useNavigate()
    const location = useLocation()
    const { id: jobId } = useParams()

    const filesEditable = useMemo(() => {
        if (!job) {
            return
        }

        if (curUser.isRole('employee')) {
            return job.status === 'progress'
        }

        if (curUser.isRole('employer')) {
            return false;
        }
    }, [job, curUser])

    const filesViewable = useMemo(() => {
        if (!job) {
            return
        }

        if (curUser.isRole('employee')) {
            return true;
        }

        if (curUser.isRole('employer')) {
            return ['review', 'complete'].includes(job.status);
        }
    }, [job, curUser])

    // * Could be used if we want to separate the viewable logic from the downloadable logic
    const filesDownloadable = useMemo(() => {
        if (!job) {
            return
        }

        if (curUser.isRole('employee')) {
            return true;
        }

        if (curUser.isRole('employer')) {
            return ['review', 'complete'].includes(job.status);
        }
    }, [job, curUser])

    const primaryActionButton = useMemo(() => {
        if (!job) {
            return
        }

        if (curUser.isRole('employee')) {
            if (job.status === 'progress') {
                return {
                    text: "Submit for Review",
                    onClick: submitForReview,
                    style: {},
                    className: ""
                }
            }
        }

        if (curUser.isRole('employer')) {
            if (job.status === 'review') {
                return {
                    text: "Mark as Complete",
                    onClick: markAsComplete,
                    style: {},
                    className: ""
                }
            }
        }
    }, [job, curUser])

    const secondaryActionButton = useMemo(() => {
        if (!job) {
            return
        }

        if (curUser.isRole('employee')) {
            return;
        }

        if (curUser.isRole('employer')) {
            if (job.status === 'review') {
                return {
                    text: "Return for Modification",
                    onClick: returnForModification,
                    style: {},
                    className: ""
                }
            }
        }
    }, [job, curUser])

    // FILE HANDLERS
    const handleFileDrop = (event) => {
        if (curUser.isRole('employer')) {
            return;
        }

        event.preventDefault();
        const newFiles = event.dataTransfer.files
        setSessionUploadedFiles(prev => [...prev, ...newFiles])
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        // Add styling or effects to indicate the drop area
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        // Remove styling or effects from the drop area
    };

    /**
     * 
     * @type {React.ChangeEventHandler<HTMLInputElement>}
     */
    const handleFileChange = (event) => {
        if (curUser.isRole('employer')) {
            return;
        }

        event.preventDefault();
        const newFiles = event.target.files
        setSessionUploadedFiles(prev => [...prev, ...newFiles])
    };

    // JOB FLOW HANDLERS
    async function submitForReview() {
        setLoading(true)
        axios.post('/jobs/submit', {
            job: jobId,
            user: curUser.id,
            fileCount: sessionUploadedFiles.length + previouslyUploadedFiles.length
        })
            .then(() => {
                setJob(prev => ({ ...prev, status: 'review', timeSubmitted: new Date() }))
            })
            .catch(error => {
                console.error(error)
                alert(error.response?.data || error.message)
            })
            .finally(() => setLoading(false))
    }

    async function markAsComplete() {
        setLoading(true)
        axios.post('/jobs/complete', {
            job: jobId,
            user: curUser.id,
        })
            .then(() => {
                setJob(prev => ({ ...prev, status: 'complete', timeCompleted: new Date() }))
            })
            .catch(error => {
                console.error(error)
                alert(error.response?.data || error.message)
            })
            .finally(() => setLoading(false))
    }

    async function returnForModification() {
        setLoading(true)
        axios.post('/jobs/reject', {
            job: jobId,
            user: curUser.id,
        })
            .then(() => {
                setJob(prev => ({ ...prev, status: 'progress', timeRejected: new Date() }))
            })
            .catch(error => {
                console.error(error)
                alert(error.response?.data || error.message)
            })
            .finally(() => setLoading(false))
    }

    async function fetchData() {
        var result;
        try {
            result = await axios.get(`/jobs/view/${jobId}`, {
                params: {
                    getOwner: true,
                    getUserStatus: true,
                    getJobFiles: true,
                    user: curUser.id
                }
            })
            setJob(result.data.job)
            setOwner(result.data.owner)

            // If this user has created an application for this job
            setTeamMembers(
                result.data.job.application.users || [])

            if (result.data.job.files) {
                setPreviouslyUploadedFiles(result.data.job.files)
            }
        } catch (error) {
            console.error(error)
            alert(error.response?.data || error.message)
        }
    }

    useEffect(() => {
        if (!curUser.loggedIn) {
            navigate('/Login', { state: { from: location.pathname + location.search } })
            return;
        }

        fetchData()
    }, [curUser.loggedIn])

    return (
        <div className='ApplyForasPage'>
            <div className='ForsaDetails'>
                <div className='leftDetails'>
                    {
                        owner ?
                            <><h4>About the client</h4>
                                <div className='leftProfile'>
                                    <ProfileImage id={owner._id} imageURL={owner.imageURL} />
                                    <div className='ProfileDetails'>
                                        <Link to={`/Profile?id=${owner._id}`}><h4 style={{ marginBottom: '1vh' }} > {owner.fullName}</h4></Link>
                                        {/* <img src = {RatingGround}/> */}
                                        <Rating initialValue={owner.rating?.average} readonly={true} allowFraction={true} size={'20'} />
                                    </div>
                                </div>
                                <div className='moreDetails'>
                                    <h4>Joined since {new Date(owner.createdAt).toLocaleDateString('en-eg', { day: '2-digit', month: 'long', year: 'numeric' })}</h4>
                                    <h4>{owner.jobCount || 0} For{owner.jobCount === 1 ? "sa" : "as"} Created</h4>
                                </div>
                                <Link to={`/Profile?id=${owner._id}`}><h4>Other Foras by {owner.fullName?.split(' ')[0]}</h4></Link></>
                            : <Loading />
                    }
                </div>
                <div className='rightDetails'>
                    {job ?
                        <>
                            <div className='jobUpTag'>
                                <div className='jobTitle'>
                                    <h3>{job.title}</h3>
                                </div>
                                <div className='jobPayment' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <h3>{`${job.isBid ? job.application.bid : job.price}LE`}</h3>
                                </div>
                            </div>

                            <div className='PropertyTags'>
                                <div className='SecondTag'>
                                    <div className='duration'>
                                        {job.status === 'progress' && <h4>Started {calculateTimeAgo(job.timeAccepted)}</h4>}
                                        {job.status === 'review' && <h4>Submitted {calculateTimeAgo(job.timeSubmitted)}</h4>}
                                        {job.status === 'complete' && <h4>Completed {calculateTimeAgo(job.timeCompleted)}</h4>}
                                    </div>
                                </div>

                                <div className='ThirdTag'>
                                    <h4>{job.description}</h4>
                                </div>
                                <div>
                                    <h3>Uploaded Files:</h3>
                                    {filesViewable && previouslyUploadedFiles.map((e, i) => {
                                        return (
                                            <JobFile
                                                key={i}
                                                file={e}
                                                index={i}
                                                curUser={curUser}
                                                jobId={jobId}
                                                setFiles={setPreviouslyUploadedFiles}
                                                initialStatus={'uploaded'}
                                                editable={filesEditable}
                                                viewable={filesViewable}
                                                downloadable={filesDownloadable}
                                            />)
                                    })}
                                </div>
                                <div
                                    className='dropArea'
                                    onDrop={handleFileDrop}
                                    onDragOver={(event) => event.preventDefault()}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                >
                                    {sessionUploadedFiles.length > 0 ? (
                                        sessionUploadedFiles.map((e, i) => (
                                            <JobFile
                                                key={i}
                                                file={e}
                                                index={i}
                                                curUser={curUser}
                                                jobId={jobId}
                                                setFiles={setSessionUploadedFiles}
                                                initialStatus={'initial'}
                                                editable={filesEditable}
                                                viewable={filesViewable}
                                                downloadable={filesDownloadable}
                                            />
                                        )))
                                        :
                                        filesEditable && <p>Drag and drop a file here or click to upload.</p>
                                    }
                                </div>
                                {filesEditable && <input type='file' multiple onChange={handleFileChange} />}

                                <p>Meet the team!</p>
                                <div className="TeamMembers">
                                    {teamMembers.map((e, i) => {
                                        return <Link key={i} to={`/Profile?id=${e.user._id}`} className="TeamMember" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                                            <ProfileImage id={e.user._id} imageURL={e.user.imageURL} size={50} />
                                            <h4>{e.user.username} {e.user._id == curUser.id ? '(You)' : ''}</h4>
                                        </Link>
                                    })}
                                </div>
                                <div className='FourthTag'>
                                    {primaryActionButton && <button disabled={loading} {...primaryActionButton}>
                                        {loading ? <Loading color="white" size={25} /> : primaryActionButton.text}
                                    </button>}
                                    {secondaryActionButton && <button disabled={loading} {...secondaryActionButton}>
                                        {loading ? <Loading color="white" size={25} /> : secondaryActionButton.text}
                                    </button>}
                                </div>

                            </div>
                        </>
                        : <Loading />}
                </div>
            </div>
        </div>
    )
}