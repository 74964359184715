import React, { useState } from 'react'
import "./stylesheets/About.css"
import About1 from './stylesheets/imgs/About1_1.png'
import About2 from './stylesheets/imgs/About1_2_New.png'
import About3 from './stylesheets/imgs/About1_3.png'
import About4 from './stylesheets/imgs/About1_4.png'
import About5 from './stylesheets/imgs/About2.png'
import About6 from './stylesheets/imgs/About3.png'
import { Link } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa';

const SectionAbout = ({ mainText, subText }) => {
  return (
    <div id='SectionAbout'>
      <h2 id='VisionText'>{mainText}</h2>
      <h3 id='VisionSubText'>
        {subText}
      </h3>
    </div>
  )
}


const SubSectionAbout = ({ mainText, subText }) => {
  return (
    <div id='SectionAbout'>
      <h2 id='SubVisionText'>{mainText}</h2>
      <h3 id='SubVisionSubText'>
        {subText}
      </h3>
    </div>
  )
}


const CompanyIntership = () => {
  const [activeTab, setActiveTab] = useState('Companies');

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div id='CompIntern'>
      <div id='SwitcherCompIntern' style={{ width: '50%', display: 'flex', flexDirection: 'row', borderStyle: 'solid', borderColor: 'white', borderRadius: 20, borderColor: '#34AED4', borderWidth: 0.5, userSelect: 'none' }}>
        <a
          id='Switcher'
          onClick={() => handleTabSwitch('Companies')}
          style={{
            backgroundColor: activeTab === 'Companies' ? '#34AED4' : 'white',
            color: activeTab === 'Companies' ? 'white' : '#34AED4',
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            cursor: 'pointer',
            transition: 'background-color 0.3s, color 0.3s',
            fontSize: '2svh'
          }}
        >
          Companies
        </a>
        <a
          id='Switcher'
          onClick={() => handleTabSwitch('Internship')}
          style={{
            backgroundColor: activeTab === 'Internship' ? '#34AED4' : 'white',
            color: activeTab === 'Internship' ? 'white' : '#34AED4',
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            cursor: 'pointer',
            transition: 'background-color 0.3s, color 0.3s'
          }}
        >
          Internship
        </a>
      </div>
      <div
        id='SwitcherImages'
        style={{
          backgroundImage: `url(${activeTab === 'Companies' ? About5 : About6})`,
          transition: 'background-image 0.3s',
        }}
      >
        <h3>{activeTab === 'Companies' ? 'For Companies' : 'For Interns'}</h3>
        <h4 id='ImageText'>
          {activeTab === 'Companies' ?
            'At FORAS, we understand the immense value that young talent can bring to your organization. Our platform provides a seamless way for companies to connect with the next generation of leaders and build a robust pipeline of future employees.' :
            'At FORAS, we are committed to empowering the next generation of professionals by connecting you with the most exciting internship and junior-level opportunities..'
          }
        </h4>
      </div>

      <div id='TextDisplay'>
        {activeTab === 'Companies' ? (
          <div>
            <h3 id='SubVisionSubText'>
              Partnering with FORAS allows your company to:
            </h3>
            <SubSectionAbout
              mainText={"Tap into Fresh Perspectives"}
              subText={"Interns bring a unique blend of enthusiasm, innovation, and up-to-date knowledge, which can infuse your team with new ideas and drive growth."}
            />
            <SubSectionAbout
              mainText={"Assess Potential Hires"}
              subText={"Hosting interns gives you the opportunity to evaluate their skills, work ethic, and cultural fit, allowing you to identify the best candidates for potential full-time positions."}
            />
            <SubSectionAbout
              mainText={"Enhance Productivity"}
              subText={"Interns can take on various tasks and projects, providing additional support to your team and freeing up your employees to focus on strategic initiatives."}
            />
            <SubSectionAbout
              mainText={"Boost Employer Branding"}
              subText={"By offering meaningful internship opportunities, you can strengthen your reputation as a top employer of choice, attracting top talent and building a strong talent pipeline."}
            />
          </div>
        ) : (
          <div>
            <h3 id='SubVisionSubText'>
              Partnering with FORAS allows you to:
            </h3>
            <SubSectionAbout
              mainText={"Unlock Your Potential"}
              subText={"Our platform provides you with a centralized hub to explore a diverse range of internships and entry-level positions across various industries. Whether you're seeking to gain practical experience, build your professional network, or kickstart your career, we have the resources and connections to help you succeed."}
            />
            <SubSectionAbout
              mainText={"Streamline Your Job Search"}
              subText={"Our user-friendly interface allows you to easily search for opportunities that align with your interests, skills, and career aspirations. With detailed job descriptions, company profiles, and application tools at your fingertips, you can seamlessly navigate the job market and submit your applications with confidence."}
            />
            <SubSectionAbout
              mainText={"Unlock Personalized Guidance"}
              subText={"In addition to connecting you with potential employers, we offer a range of resources to support your professional development. From resume and cover letter reviews to interview preparation and career coaching, our team is dedicated to helping you showcase your best self and land your dream opportunity."}
            />
          </div>
        )}
    <h2 id='SubVisionText' style={{ marginBottom: '.5%', marginTop: '2.5%' }}>
          {activeTab === 'Companies' ? 'How To Get Started' : 'Join Our Community'}
        </h2>
            <h3 id='SubVisionSubText'>
          {activeTab === 'Companies' ?
            'Joining our platform is quick and easy. Simply create an account, create your profile, post your available internship and junior-level positions, and start connecting with the next generation of professionals. Our intuitive interface and dedicated support team will guide you through the process, ensuring a seamless and efficient recruitment experience.' :
            `By becoming a part of the FORAS community, you'll gain access to a network of like-minded individuals, industry mentors, and alumni who can provide invaluable insights and support throughout your career journey ${"\n"} Start your path to success today by creating your profile and exploring the endless possibilities awaiting you on our platforms.`
          }
        </h3>
        {/* <div id='CreateAccountAbout'> */}
          <Link to="/Login_SignUp" id='CreateAccountAboutText' style={{ textDecoration: 'none' }}>Create Account</Link>
        {/* </div> */}
      </div>
    </div>
  );
};

export default function About() {
  return (
    <div  id='AboutWrapper' style={{overflowX:'hidden'}}>
      <SectionAbout mainText={"Our Vision"}
        subText={"At FORAS, our vision is to empower the next generation of young professionals by connecting them with meaningful internship and entry-level opportunities. We believe that a strong start to one's career is crucial for long-term success, and we are dedicated to bridging the gap between ambitious students/graduates and companies seeking top talent."} />
 
      <div id='FirstBatchAbout'>
        <div id='RowImages'>
          <img id='Col1Row1' src={About1} />
          <img id='Col2Row1' src={About2} />
        </div>
        {/* <div id='RowImagesSecond'>
          <img id='Col1Row2' src={About3} />
          <img id='Col2Row2' src={About4} />
        </div> */}
      </div>
      <SectionAbout mainText={"Our Mission"}
        subText={"Our mission is to revolutionize the way students and fresh graduates discover and secure their dream jobs. Through our innovative platform, we aim to:"} />
      <div id='OurMission'>
        <div id='MissionRegion'>
          <div id='MissionTitle'>
          <h3>Creating Opportunities</h3>
          </div>
          <h4>Curate a diverse range of internships and junior positions across various industries, ensuring our users have access to the best available options.</h4>
        </div>
        <FaArrowRight size={20} color='#34AED4'/>
        <div id='MissionRegion'>
          <div id='MissionTitle'>
          <h3>Meaningful Connections</h3>
          </div>
          <h4>Facilitate genuine interactions between talented individuals and companies seeking to build their future workforce, creating mutually beneficial relationships.</h4>
        </div>
        <FaArrowRight size={20} color='#34AED4'/>
        <div id='MissionRegion'>
          <div id='MissionTitle'>
          <h3>Empower Career Development</h3>
          </div>
          <h4>Offer comprehensive resources, guidance, and mentorship to help our users navigate the job market, refine their skills, and achieve their professional aspirations.</h4>
        </div>
      </div>
{/* 
      <SubSectionAbout mainText={"Creating Opportunities"}
        subText={"Curate a diverse range of internships and junior positions across various industries, ensuring our users have access to the best available options."} />

      <SubSectionAbout mainText={"Meaningful Connections"}
        subText={"Facilitate genuine interactions between talented individuals and companies seeking to build their future workforce, creating mutually beneficial relationships."} />


      <SubSectionAbout mainText={"Empower Career Development"}
        subText={"Offer comprehensive resources, guidance, and mentorship to help our users navigate the job market, refine their skills, and achieve their professional aspirations"} /> */}

      <CompanyIntership />

    </div>
  )
}