import axios from "axios";
import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Loading from "../Loading";
import '../stylesheets/ProfileSection.css';
import AlertModal from "../AlertModal";

export default function ProfileSection({ name, header, user, isCurUser }) {
  const [isEditing, setIsEditing] = useState(false);
  const [rows, setEditedRows] = useState(user[name]);
  const [updating, setUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [originalRows, setOriginalRows] = useState(user[name]); // Store original state

  const handleEditClick = (e) => {
    e.preventDefault(); // Prevent the default scrolling behavior
    setOriginalRows(rows); // Save original state on edit
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (rows.some(row => !row.title.trim() || !row.subTitle.trim() || !row.rightSide.trim())) {
      setShowModal(true); // Show the modal instead of alert
      return;
    }
    setIsEditing(false);
    setUpdating(true);
    axios.put('/users/update', {
      user: user._id,
      [name]: rows
    })
      .catch(error => console.error(error))
      .finally(() => setUpdating(false));
  };

  const handleAddRow = (e) => {
    e.preventDefault(); // Prevent the default scrolling behavior
    const newRow = {
      title: '',
      rightSide: '',
      subTitle: ''
    };
    setEditedRows([newRow, ...rows]);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setEditedRows(updatedRows);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedRows = [...rows];
    const [removed] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, removed);
    setEditedRows(updatedRows);
  };

  const handleCloseClick = (e, index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setEditedRows(updatedRows);
  };

  const handleDiscardChanges = () => {
    setEditedRows(originalRows); // Revert to original state
    setIsEditing(false);
    setShowModal(false); // Close the modal
  };

  if (!isCurUser && !(rows?.length > 0)) {
    return null;
  }

  return (
    <div id="ProfileSectionNew">
        <AlertModal 
        show={showModal} 
        text="Oops! Looks like you missed a spot. Please fill out all fields before saving." 
        widthController={50} 
        alertText="" 
        buttonsVisible={true} 
        b1={{ text: "Got it!", onClick: () => setShowModal(false) }} 
        b2={{ text: "Discard Changes", onClick: handleDiscardChanges }} // Add discard changes button
        b2bg = "#00000050" 
      />
      <div className="header">
        <h2 id="headerProfileSection" style={{ fontStyle: 'italic', fontSize: '2.5svh', marginBottom: '1%' }}>{header}</h2>
        <div>
          {updating ? <Loading /> : (
            isEditing ? (
              <>
                <a href="#" className="linkButton" onClick={handleAddRow}>Add Row</a>
                <a href="#" className="linkButton" onClick={handleSaveClick}>Save</a>
              </>
            ) : (isCurUser && <a className="linkButton" onClick={handleEditClick}>Edit</a>)
          )}
        </div>
      </div>
      {rows?.length > 0 ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={header}>
            {(provided) => (
              <table className="table" {...provided.droppableProps} ref={provided.innerRef}>
                <thead className="tableHeader">
                  <tr>
                    <th className="tableText">Title</th>
                    <th className="tableText">Company</th>
                    <th className="tableText">Years of Experience</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <Draggable key={index} draggableId={`${name}-${index}`} index={index}>
                      {(provided) => (
                        <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="tableRow">
                          <td className="tableCell">
                            {isEditing ? (
                              <>
                                <input
                                  type='text'
                                  value={row.title}
                                  placeholder="Enter your title"
                                  onChange={(e) => handleRowChange(index, 'title', e.target.value)}
                                  required
                                />
                              </>
                            ) : (
                              <h3 className="h3Text">{row.title}</h3>
                            )}
                          </td>
                          <td className="tableCell">
                            {isEditing ? (
                              <input
                                type='text'
                                value={row.subTitle}
                                placeholder="Enter company name"
                                onChange={(e) => handleRowChange(index, 'subTitle', e.target.value)}
                                required
                              />
                            ) : (
                              <h3 className="h3Text">{row.subTitle}</h3>
                            )}
                          </td>
                          <td className="tableCell">
                            {isEditing ? (
                              <input
                                type='text'
                                value={row.rightSide}
                                placeholder="e.g 2015-2016"
                                onChange={(e) => handleRowChange(index, 'rightSide', e.target.value)}
                                required
                              />
                            ) : (
                              <h3 className="h3Text">{row.rightSide}</h3>
                            )}
                            {isEditing && (
                              <span className="removeButton" onClick={e => handleCloseClick(e, index)}>Remove</span>
                            )}
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <h4 style={{ margin: 0, padding: 0, color: 'black', fontSize: '1.5svh', paddingLeft: '0.5%', fontStyle: 'italic' }}>Edit to add new items</h4>
      )}
    </div>
  );
}
