import React, { useState } from 'react';
import './stylesheets/contact.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BACKEND } from './config';
import Loading from 'react-loading';
import AlertModal from './pages/components/AlertModal';

export default function ContactUs() {
  const [complaintData, setComplaintData] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // State to control the modal visibility
  const [modalText, setModalText] = useState(''); // State to control the modal text

  const nav = useNavigate();
  const maxCharacters = 250;

  const handleDataChange = (field) => (event) => {
    const inputText = event.target.value;
    if (field !== 'complaint' || inputText.length <= maxCharacters) {
      setComplaintData((prev) => {
        const updated = { ...prev };
        updated[field] = inputText;
        return updated;
      });
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${BACKEND}/complaints`, complaintData)
      .then(() => {
        setLoading(false);
        setModalText("Complaint Submitted");
        setModalVisible(true); // Show the modal instead of alert
      })
      .catch((error) => {
        setLoading(false);
        setModalText(error.message);
        setModalVisible(true); // Show the modal instead of alert
      });
  }

  const closeModal = () => {
    setModalVisible(false);
    nav('/'); // Navigate after closing the modal
  };

  return (
    <div className="ContactView">
      <form onSubmit={handleSubmit} style={{ width: '62.5%' }}>
        <div className="ContactBox">
          <h1 className="ContactTitle">Contact Us</h1>
          <div className="ContactRow">
            <input
              id="fullName"
              type="text"
              placeholder="Your Name"
              onChange={handleDataChange('fullName')}
              value={complaintData.fullName}
            />
          </div>
          <div className="ContactRow">
            <input
              id="email"
              type="text"
              placeholder="Your Email or Phone Number"
              onChange={handleDataChange('email')}
              value={complaintData.email}
            />
          </div>
          <div className="ContactRow">
            <h7>{maxCharacters - (complaintData.complaint?.length || 0)} characters left</h7>
            <textarea
              id="complaint"
              value={complaintData.complaint}
              onChange={handleDataChange('complaint')}
              placeholder="Insert Message here"
              rows={4}
              maxLength={maxCharacters}
            />
          </div>
          <button disabled={loading}>
            {loading ? <Loading type="spinningBubbles" height={20} width={20} /> : 'Submit'}
          </button>
        </div>
      </form>

      <div style={{marginTop:'1%'}}>
        <h4 style={{ fontSize: '1.5svh', color: 'black' }}>
          You can email us at foras.freelance@gmail.com or refer to our{' '}
          <Link id="QuickLinks" style={{ textDecoration: 'none' }} to="/PrivacyPolicy">
            Privacy Policy
          </Link>{' '}
          or{' '}
          <Link id="QuickLinks" style={{ textDecoration: 'none' }} to="/TermsCondition">
            Terms and Conditions
          </Link>{' '}
          for more information
        </h4>
      </div>

      {/* Alert Modal */}
      <AlertModal
        show={modalVisible}
        text={modalText}
        widthController={50}
        buttonsVisible={true}
        b2={{ text: 'OK', onClick: closeModal }}
        b2bg = {"#34AED4"}
      />
    </div>
  );
}
