import React, { useState, useEffect } from 'react';
import "./stylesheets/Foras.css";
import JobBanner from './components/JobBanner';
import ForasTag from './components/ForasTag';
import { BACKEND } from '../config';
import axios from 'axios';
import { Buffer } from 'buffer';
import { calculateTimeAgo } from '../utils/JobUtils';
import { FaSearch } from 'react-icons/fa';

const searchLimit = 20;

export default function Foras() {
  const [activeTags, setActiveTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [jobTypes, setJobTypes] = useState([])
  
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedJobTypes, setSelectedJobTypes] = useState(new Set());

  const [jobs, setJobs] = useState([]);
  const [latestQueryParams, setLatestQueryParams] = useState()
  const [moreAvailable, setMoreAvailable] = useState(false)

  useEffect(() => {
    fetchOptions();
    search()
  }, []);

  // const handleClickTag = (index) => {
  //   if (activeTags.includes(index)) {
  //     setActiveTags(activeTags.filter((item) => item !== index));
  //   } else {
  //     setActiveTags([...activeTags, index]);
  //   }
  // };

  const fetchOptions = () => {
    if (categories.length > 0) {
      return;
    }
    axios.get(`/categories/`, { params: { getTypes: true } })
      .then(result => {
        setCategories(result.data?.categories);
        setJobTypes(result.data?.jobTypes)
      }).catch(error => {
        console.log(error);
        setCategories([]);
      });
  };

  const generateCategoryList = () => {
    return categories.map((option) => (
      <label key={option._id} className="radio-label" style={{ marginBottom: '5%' }}>
        <input
          type="radio"
          name="category"
          value={option._id}
          checked={selectedCategory === option._id}
          onChange={handleCategoryChange}
        />
        {option.name}
      </label>
    ));
  };

  const generateJobTypeList = () => {
    return Object.entries(jobTypes).map(([value, label]) => (
      <label className="radio-label" style={{ marginBottom: '5%' }}>
        <input
          type="checkbox"
          value={value}
          name='jobType'
          checked={selectedJobTypes.has(value)}
          onChange={handleJobTypeChange}
        />
        {label}
      </label>
    ));
  }

  const handleClickTag = (index) => {
    setActiveTags((prevTags) => {
      if (prevTags.includes(index)) {
        return prevTags.filter(tagIndex => tagIndex !== index);
      } else {
        return [...prevTags, index];
      }
    });
  };


  const generateTags = () => {
    if (selectedCategoryTags.length === 0) {
      return Array.from({ length: 5 }).map((_, index) => (
        <label key={index} id="tag-label" style={{ fontStyle: 'italic', fontSize: '1.75svh', color: '#00000080' }}>
          <input
            type="checkbox"
            disabled
          />
          Choose Category
        </label>
      ));
    }

    return selectedCategoryTags.map((button, index) => (
      <label key={index} id="tag-label">
        <input
          type="checkbox"
          checked={activeTags.includes(index)}
          onChange={() => handleClickTag(index)}
        />
        {button}
      </label>
    ));
  };



  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setActiveTags([]);
  };

  const handleJobTypeChange = (event) => {
    setSelectedJobTypes(prev => {
      const updated = new Set(prev)
      if (updated.has(event.target.value)) {
        updated.delete(event.target.value);
      } else {
        updated.add(event.target.value);
      }
      return updated
    });
  };

  const selectedCategoryTags = categories.find((option) => option._id === selectedCategory)?.tags || [];

  const search = () => {
    let q = document.getElementById('search').value;
    let cat = selectedCategory;
    let tags = selectedCategoryTags.filter((e, i) => activeTags.includes(i));
    let types = Array.from(selectedJobTypes)

    const queryParams = {
      q,
      cat,
      limit: searchLimit,
    };


    if (tags.length > 0) {
      let json = JSON.stringify(tags);
      let enc = Buffer.from(json).toString('base64');
      enc = enc.replace(/[+/=]/g, function (match) {
        return match === '+' ? '-' : match === '/' ? '_' : '';
      });
      queryParams.tags = enc;
    }
    if (types.length > 0) {
      let json = JSON.stringify(types);
      let enc = Buffer.from(json).toString('base64');
      enc = enc.replace(/[+/=]/g, function (match) {
        return match === '+' ? '-' : match === '/' ? '_' : '';
      });
      queryParams.types = enc;
    }

    axios.get(`/jobs/search`, { params: queryParams })
      .then(result => {
        setJobs(result.data);
        setLatestQueryParams(queryParams)
        setMoreAvailable(result.data.length === searchLimit)
      })
      .catch(err => {
        console.log(err);
        setJobs([]);
      });
  };

  const loadMore = () => {
    if (!latestQueryParams) return search();

    const queryParams = { ...latestQueryParams }
    const latestJob = jobs?.at(-1)
    if (queryParams.q?.length > 0) {
      queryParams.searchAfter = latestJob?.paginationToken
    } else {
      queryParams.queryCursor = latestJob?.createdAt
    }

    axios.get(`/jobs/search`, { params: queryParams })
      .then(result => {
        setJobs(prev => [...prev, ...result.data]);
        setMoreAvailable(result.data.length === searchLimit)
      })
      .catch(err => {
        console.log(err);
        setJobs([]);
      });
  }

  const generateJobCards = () => {
    return jobs.map(job => {
      return <JobBanner
        key={job._id}
        job={job}
      />;
    });
  };

  const handleReset = () => {
    setSelectedCategory('');
    setActiveTags([]);
    document.getElementById('search').value = '';
    setSelectedJobTypes(new Set());
  };

  return (
    <div className='ForasPage'>
      <div className='SearchForsa'>
        <div id='FilterArea'>
          <h4 style={{ color: 'black', fontSize: '1.5svh' }}>Filters</h4>
          <a onClick={handleReset} style={{ color: 'black', fontSize: '1.5svh', cursor: 'pointer' }}>Reset</a>
        </div>
        <input id='search' type="search" onKeyDown={
          (e) => { if (e.key === 'Enter') { search() } }}
          placeholder=' Search' />
        <div id='CategoryArea' style={{ marginTop: '1.25%' }}>
          <h4 style={{ fontWeight: 'bold', color: 'black' }}>Job Type</h4>
          {/* TODO The new options added in foras */}
          <form style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom:0 }} id='generateOptions'>
            {generateJobTypeList()}
          </form>
        </div>

        <div id='CategoryArea'>
          <h4 style={{ fontWeight: 'bold', color: 'black' }}>Job Category</h4>
          <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }} id='generateOptions' onFocus={fetchOptions}>
            {generateCategoryList()}
          </form>
          <h4 style={{ fontWeight: 'bold', color: 'black' }}>Job Tags</h4>
          <div className='displayTopicsSection'>
            {generateTags()}
          </div>
        </div>
        <button onClick={search} style={{ width: '80%', borderRadius: 5, padding: 10, alignSelf: 'center', marginBottom: '5%' }}>Filter</button>
      </div>

      <div className="JobDisplaySection">
        <div className='rightSide'>
          {generateJobCards()}
        </div>
        <div id='LoadMoreArea'>
          {moreAvailable && <button id='LoadMoreButton' onClick={loadMore}>Load More</button>}
        </div>
      </div>
    </div>
  );
}
