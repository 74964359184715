import React, { useEffect, useRef, useState } from 'react'
import BackgroundProfile from '../../stylesheets/imgs/BackgroundProfile.png'
import ProfileImg from '../../stylesheets/imgs/ProfileImg.png'
import ProfileJobs from './ProfileJobs'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading';
import ProfileImage from '../ProfileImage';
import { maxUserFileSize, validImageTypes, validResumeTypes } from '../../../config.js';
import "../../stylesheets/Profile.css"

const availableAppStatuses = {
    pending: {
        name: "Pending",
        url: '/jobs/pendingCreatedBy',
    },
    progress: {
        name: 'Active',
        url: `/jobs/listByStatus`,
        params: { employer: true, status: 'progress' }
    },
    review: {
        name: 'In Review',
        url: '/jobs/listByStatus',
        params: { employer: true, status: 'review' }
    },
    complete: {
        name: "Complete",
        url: '/jobs/listByStatus',
        params: { employer: true, status: 'complete' }
    },
}


const ActiveJob = ({ job, isCurUser, selectedAppStatus }) => {

    let buttonLink;
    let buttonText;
    if (isCurUser) {
        if (selectedAppStatus === 'pending') {
            buttonLink = `/Applicants/${job._id}`
            buttonText = 'View Applicants'
        } else {
            buttonLink = `/ActiveForsa/${job._id}`
            buttonText = 'View Job'
        }
    }
    else {
        buttonLink = `/ApplyForas/${job._id}`
        buttonText = 'Apply'
    }
    return <>
    <div className='DisplayJobEmployer'>
        <h3 id='DisplayJobEmployerTitle'>{job.title}</h3>
        <h4 id='DisplayJobEmployerDesc'>
            {job.description.slice(0, 100)}{job.description.length > 100 ? '...' : ''}
        </h4>
        <Link to={buttonLink}>
            <button id='ButtonEmployerProfile' style={{ width: '100%', fontSize:'1.25svh', fontWeight:600 }}>
                {buttonText}
            </button>
        </Link>
    </div>
</>

};
export default function EmployerProfile({ employer, curUser }) {
    const isCurUser = employer._id === curUser.id
    const [history, setHistory] = useState(undefined)
    // const [activeJobs, setActiveJobs] = useState(undefined)
    const [selectedAppStatus, setSelectedAppStatus] = useState('pending')
    const [dataList, setDataList] = useState()
    const imageInputRef = useRef()
    const navigate = useNavigate()
    const location = useLocation()
    const [uploading, setUploading] = useState(false)

    const [imageProgress, setImageProgress] = useState()
    const [imageUploadedTime, setImageUploadedTime] = useState()

    function handleImageChoice(event) {
        if (!isCurUser) {
            return;
        }
        event.preventDefault()
        imageInputRef.current?.click()
    }

    /**
     * 
     * @param {"image" | "cv"} docType 
     * @returns 
     */
    const handleUploadFile = (docType) => function (event) {
        event.preventDefault();

        // Validating size
        const file = event.target.files[0]
        if (!file) {
            return;
        }

        if (file.size > maxUserFileSize) {
            console.error(`File too large. Max size is ${maxUserFileSize / (1024 * 1024)}MB`);
            event.target.value = null
            return;
        }

        const validTypes = docType === 'cv' ? validResumeTypes : validImageTypes

        // Validating file type
        if (!validTypes.split(',').includes(file.type)) {
            console.error("Invalid file type. Allowed types are: " + validTypes);
            event.target.value = null
            return;
        }

        // Creating form for axios

        if (!curUser.loggedIn) {
            navigate('/Login', { state: { from: location.pathname + location.search } })
            return;
        }

        const formData = new FormData();
        formData.append('user', curUser.id);
        formData.append('type', docType);
        formData.append('file', file)

        setImageProgress(0)
        setUploading(true)
        axios.post('/users/files/upload', formData, {
            onUploadProgress: e => {
                if (docType !== 'image') {
                    return;
                }
                setImageProgress(e.progress)
            }
        })
            .then(() => {
                if (docType !== 'image') {
                    return;
                }
                setImageUploadedTime(Date.now())
            })
            .catch(error => console.error(error))
            .finally(() => setUploading(false))

    }

    function genProfileHistory() {
        if (!history) {
            return <Loading />
        }

        return history.map(e => <ProfileJobs key={e._id} jobTitle={e.title} jobDesc={e.description} />)
    }

    function genActiveJobs() {
        if (!dataList) {
            return <Loading />
        }

        return dataList.map(job => {
            return <ActiveJob job={job} isCurUser={isCurUser} selectedAppStatus={selectedAppStatus} />
        })
    }

    function handleSelectedAppStatusChange(key) {
        setSelectedAppStatus(key)

        // FIXME: This should fetch normal job if an employee user is visiting this profile
        const appStatusObj = availableAppStatuses[key]
        axios.get(appStatusObj.url + `/${employer._id}`, { params: appStatusObj.params })
            .then(result => {

                // Workaround to keep schema similar for all keys
                if (key === 'progress') {
                    result.data.map(e => e.job = { ...e })
                }
                // console.log(result.data)
                setDataList(result.data)
            })
            .catch(error => {
                console.error(error)
                alert(error.message)
            })
    }

    useEffect(() => {
        handleSelectedAppStatusChange(selectedAppStatus)
    }, [])

    useEffect(() => {
        axios.get(`/jobs/history/${employer._id}`, {
            params: {
                limit: 3,
                owner: true
            }
        })
            .then(result => {
                setHistory(result.data)
            })
            .catch(error => {
                setHistory([])
                console.log(error);
            })

        // axios.get(`/jobs/createdBy/${employer._id}`)
        //   .then(result => setActiveJobs(result.data))
        //   .catch(error => {
        //     console.error(error);
        //   })
    }, [employer])

    return (
        <div className='ProfilePage'>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div className='ProfileTag' style={{width:'100%'}}>
                    {/* <img className='ProfileImg' src={ProfileImg} /> */}
                    <ProfileImage progress={imageProgress} uploadedTime={imageUploadedTime} className='ProfileImg' id={employer._id} imageURL={employer.imageURL} style={{ cursor: isCurUser ? 'pointer' : 'inherit' }} onClick={handleImageChoice} />
                    <div className='ProfileDesc'>
                    <h2 style={{ color: 'black', margin: 0, fontSize: '2.5svh' }}>{employer.fullName}</h2>
                    <h3 style={{ marginTop: 0, margin: 0, fontSize: '1.25svh'}}>@{employer.username}</h3>
                        <h3 style={{ marginTop: 0, margin: 0, fontSize: '1.25svh' }}>Foras Employer</h3>
                    </div>
                </div>
                <div className='leftSide' style={{display:'flex', flexDirection:'row', gap:'2.5%', marginRight:'10%'}}>
                        {isCurUser && <>
                            {employer.wallet && <div id="WalletCredits">
                                <h3>Wallet: {employer.wallet.credits} credits</h3>
                            </div>}
                            <div className='applybutton' id='AddCreditsButton'>
                                <Link to="/AddCredits"><button style={{ width: 175, marginBottom: 10 }}>Add Credits</button></Link>
                            </div>

                            <input disabled={uploading} type='file' ref={imageInputRef} style={{ display: 'none' }} accept={validImageTypes} onInput={handleUploadFile('image')} />
                            {uploading && <Loading />}
                            <div className='applybutton' id='AddCreditsButton'>
                                <Link to="/AddForas"><button style={{ width: 175 }}>Add Foras</button></Link>
                            </div>
                        </>}
                </div>
            </div>

            <div className='AllProfile'>
                {isCurUser && <div className='rightside'>
                    <div id='EmployerProfileSwitcher'>
                        {Object.entries(availableAppStatuses).map(([key, value]) => {
                            return (
                                <button
                                    id='SwitcherButtons'
                                    onClick={() => handleSelectedAppStatusChange(key)}
                                    style={{ backgroundColor: key === selectedAppStatus ? '#34AED4' : 'white', color: key === selectedAppStatus ? 'white' : 'black', width:'15%'}}>
                                    {value.name}
                                </button>
                            )
                        })}
                    </div>
                    <h2 id='genActiveJobs'>Jobs</h2>
                    <div id='genActiveEmployer'>
                    {genActiveJobs()}
                        </div>

                </div>}
            </div>
            <h3 style={{marginLeft:'5%', paddingBottom:'2.5%'}}>Foras History</h3>
            {genProfileHistory()}
            
        </div>
    )
}