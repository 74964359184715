import React, { useEffect, useState } from "react";
import ProfileImg from '../stylesheets/imgs/ProfileImg.png'
import './stylesheets/ProfileImage.css'
import axios from "axios";
import { BACKEND } from "../../config";


const ProfileImage = ({id, imageURL, progress, uploadedTime, size=undefined, className, style, onClick = () => {}}) => {
    const[image, setImage] = useState(undefined)

    useEffect(() => {
        async function fetchImage() {
            try {
                if(imageURL){
                  setImage(imageURL)
                  return;
                }
                if (!id) {
                  return;
                }
                
                // TODO: Since imageURLs are now publicly viewable, deprecate this after ensuring that imageURL works everywhere else.
                // * Might not end up deprecating since we don't always return imageURL from server
                const image = await axios.post(`/users/files/download`,
                  { user: id, type: "image" },
                  { headers: { "x-http-method-override": 'get' }, responseType: 'blob' })
        
                const localImageURL = URL.createObjectURL(image.data)
                setImage(localImageURL)
        
              } catch (error) {
                setImage('')
              }
        }

        fetchImage()
    }, [uploadedTime, imageURL, id])
    if (!image){
        return <img onClick={onClick} className={className} style={{width: size, height: size, borderRadius: size, objectFit: 'cover', ...style}} src = {ProfileImg}/>
    }
    return <div id="ImageProfile">
      <img onClick={onClick} className={className} style={{width: size, height: size, borderRadius: size, objectFit: 'cover', ...style}} src = {image} alt="Profile Image"/>
      </div>
}

export default ProfileImage