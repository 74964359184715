import React, { useState } from 'react'
import './stylesheets/Login.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loading from './pages/components/Loading'
import { login } from './controllers/AuthController'
import Background from './pages/stylesheets/imgs/backgroundLogin.png'
import ImageBatch from './pages/components/ImageBatch'

export default function Login() {

  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState(false)

  async function handleLogin(event) {
    event.preventDefault()
    setLoading(true)
    setLoginError(false) // Reset error state before attempting login
    try {
      await login(event, setLoading, navigate, location)
    } catch (error) {
      setLoginError(true)
      setLoading(false)
    }
  }

  return (
   <div className='LoginPage'>
    <div className='LoginWrapper'>
      <div className='RightSide'>
          <div style={{}}>
          <h3 style={{marginBottom:'5%', fontSize:'3.5svh', textAlign:'left'}}>Login</h3>
          <form onSubmit={handleLogin} style={{marginTop:'5%'}}>
            <h4 id='LoginText'>Email</h4>
          <input style={{width:'40svh', padding:'2.5%'}} type="text" id="user" name="user" placeholder='Enter Username or Email'/><br/>
          <h4 id='LoginText'>Password</h4>
          <input style={{width:'40svh', padding:'2.5%'}} type="password" id="pass" name="pass" placeholder='Password'/> <br/>
          {loginError && <p style={{color: 'red', fontSize:'1.25svh', position:'absolute'}}>Incorrect username or password</p>}
          <Link style={{fontSize:10, alignSelf:'end', marginLeft:'60%'}} to="/ForgotPassword">Forgot Password?</Link> <br/>
          {loading ? <div style={{height: '30px'}}><Loading size={20}/></div> : <input type="submit" value='Submit' />}
          </form>
           </div>

          <div className='LoginCreateAccount'>
            <h4>Don't Have An Account?</h4>
            <Link style={{color:'black'}} to='/Login_SignUp'>Don't have an account? <span style={{textDecoration:'underline'}}>Sign Up</span></Link>
          </div>
      </div>
        <ImageBatch/>
      
    </div>
   </div>
  )
}
