import { calculateTimeAgo } from "../../utils/JobUtils.js";
import "./stylesheets/JobBanner.css"
import { useNavigate } from "react-router-dom";
import { IoMdHeartEmpty } from "react-icons/io";
import { useState } from "react";

// const JobBanner = ({ jobId, jobTitle, jobPayment, jobDesc, jobDuration, jobOwner}) => {
//   const navigate = useNavigate()
//   return <>
//     <div className='jobBanner'>
//       <div className='jobTitle'>
//         <div className='leftJobTitle'>
//           <h3>{jobTitle}</h3>
//         </div>
//         <div className='rightJobTitle'>
//           <h3>{jobPayment}</h3>
//         </div>
//       </div>

//       <h4 style={{ marginTop: "0%", fontWeight: '400', color:'black' }}>{jobDesc}</h4>
//       <h4 style={{ marginTop: "0%", fontWeight: '400', color:'black' }}>{jobOwner}</h4>

//       <div className='jobTitle'>
//         <div className='leftJobTitle'>
//           <h3>Posted {jobDuration}</h3>
//         </div>
//         <div className='rightJobTitle'>
//           <button onClick={() => navigate(`/ApplyForas/${jobId}`)}>View</button>
//         </div>
//       </div>

//     </div>
//   </>
// }

const descriptionLength = 50
const HeartIcon = () => {
  const [liked, setLiked] = useState(false);

  const toggleLike = () => {
    setLiked(!liked);
  };

  return (
    <a onClick={toggleLike} style={{paddingLeft:15,paddingRight:0, paddingTop:5}}>
    <svg
      onClick={toggleLike}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={liked ? "red" : "none"}
      stroke="red"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="heart-icon"
      style={{ cursor: 'pointer', width: '2.5svh', height: '2.5svh' }}
    >
      <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
    </svg>
    </a>
  );
};

const JobBanner = ({ job }) => {
  const navigate = useNavigate()

  const payment = job.isBid ? "Up for bid" : job.price + "LE"
  const timeAgo = calculateTimeAgo(job.createdAt)
  return <>
    <a className='jobBanner'>
      <div id="HeartArea">
        {/* <IoMdHeartEmpty color="red" size={20}/> */}
        <HeartIcon/>
      </div>

      <div onClick={() => navigate(`/ApplyForas/${job._id}`)} style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
      <div id="TopArea">
        <img src={job.owner?.imageURL || "https://via.placeholder.com/30"} />
        <div id="TopDetails">
          <h4 id="TopDetailsTitle">{job.title}</h4>
          <h5 id="TopDetailText1">{job.owner?.firstName} {job.owner?.lastName}</h5>
          <h5 id="TopDetailText2">Cairo, Egypt</h5>
        </div>
        <div id='jobDurationText' style={{ width: '100%', textAlign: 'right' }}>
          <h4 >{timeAgo}</h4>
        </div>
      </div>

      <div id="BottomArea">
        <div id="JobTagsBanner">
          {
            job.tags?.slice(0, 3).map(tag =>
              <div id="JobTagSingle">
                <h5>{tag}</h5>
              </div>
            )
          }
        </div>
        <h4 style={{ color: '#34AED4' }}>{payment}</h4>
      </div>
      </div>

    </a>
  </>
}
export default JobBanner;