import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { io } from "socket.io-client"
import { BACKEND } from "./config"

export const chatSocket = io(BACKEND, {
    path: '/chats', 
    withCredentials: true, 
    closeOnBeforeunload: true,
    secure: true, 
    autoConnect: false
})
chatSocket.on('connect', () => console.log('Chat connected'))
chatSocket.on('disconnect', r => console.log('Chat disconnected:', r))
chatSocket.on("connect_error", (error) => console.log('Chat connection error:', error))

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
   </React.StrictMode>
)


 
