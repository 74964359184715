import React, { useContext } from 'react'
import "./stylesheets/Home.css"
import Background from './stylesheets/imgs/background.png'
import GraphicDesign from './stylesheets/imgs/graphicdesign.png'
import KnowForas from './stylesheets/imgs/knowForas.png'
import { Link } from 'react-router-dom'
import CompanyImage1 from '../pages/companies/image 1.png';
import CompanyImage2 from '../pages/companies/image 2.png';
import CompanyImage3 from '../pages/companies/image 3.png';
import CompanyImage4 from '../pages/companies/image 4.png';

const Services = ({ Title, Image }) => {
  return (
    <>
      {/* Link To Foras Page */}
      <Link>
        <div className='Service'>
          <img src={Image} alt="Service_Image" />
          {/* <div className='ServiceCategory'><h3>{Title}</h3></div> */}
        </div>
      </Link>
    </>
  )
}


export default function Home() {

  return (
    <div className='Home'>
      <div className='HomeIntro'>
        <div id='lefthome'>
          <h1 className='blue'>
            Choose
          </h1>
          <h2>
            Between internship or freelancer
          </h2>
          <h3>
            You can apply as a freelancer or internship or whether to do both
          </h3>

          <div id='DisplayHomeButtons'>
          <div id = 'HomeButtonIntern' style={{userSelect:'none'}}>
          <h3 className='active'>Internship</h3>
          </div>
          <div id = 'HomeButtonFreelancer' style={{userSelect:'none'}}>
            <h3 className='active'>Freelancer</h3>
          </div>
          </div>
        </div>

        <div id='righthome'>
          <img src={Background}/>
        </div>

      </div>


      <div id='DisplayCompanies'>
        <h2 className='ServiceTitle'>Our Partners</h2>
        <div className='PopularServices'>
          <Services
            Image={CompanyImage1}
          />
          <Services
            Image={CompanyImage2}
          />
          <Services
            Image={CompanyImage3}
          />
          <Services
            Image={CompanyImage4}
          />
    
        </div>
      </div>
      

    </div>
  )
}