import React, { useState } from 'react'
import './stylesheets/Login.css'
import PasswordStrengthIndicator from './pages/components/PasswordStrengthIndicator'
import axios from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Background from './pages/stylesheets/imgs/backgroundLogin.png'
import Loading from './pages/components/Loading'
import { checkPassStrength } from './utils/AuthUtils'
import { passwordStrengthThreshold } from './config'
import { getByDisplayValue } from '@testing-library/react'
import ImageBatch from './pages/components/ImageBatch'


export default function Login_SignUp() {
  const [activeButton, setActiveButton] = useState('Freelancer');
  const location = useLocation()
  const navigate = useNavigate()
  const [error, setError] = useState(undefined)
  const [strength, setStrength] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false);
  const [showNextPage, setShowNextPage] = useState(false); // New state for the next page
  const options = ['Web Development', 'Marketing', 'AI'];

  function signup(event) {
    event.preventDefault()

    let { username, email, pass, pass2, fName, lName, dob } = event.target
    username = username.value
    email = email.value
    pass = pass.value
    pass2 = pass2.value
    fName = fName.value
    lName = activeButton === 'Employer' ? 'Employer' : lName.value; // Automatically set last name to "Employer"
    dob = activeButton === 'Employer' ? '' : dob.value; // Automatically set last name to "Employer"
    let role = activeButton === 'Freelancer' ? 'employee' : activeButton === "Employer" ? 'employer' : undefined

    if (username.length === 0 || email.length === 0 || pass.length === 0 || pass2.length === 0) {
      setError("Please fill in all fields")
      return;
    }

    if (strength < passwordStrengthThreshold) {
      setError("Minimum password strength is \"Medium\"")
      return;
    }

    if (pass !== pass2) {
      setError("Passwords do not match")
      return;
    }

    setLoading(true)
    axios.post(`/users/signUp`, {
      email,
      username,
      password: pass,
      firstName: fName,
      lastName: lName,
      dob,
      role
    })
      .then(result => {
        setLoading(false)
        navigate('/Login')
      })
      .catch(error => {
        setLoading(false)
        console.error(error)
      })
  }



  const handleClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const handleNext = () => {
    if (activeButton) {
      setShowForm(true);
    } else {
      alert('Please select an option');
    }
  };


  return (
    <div className='LoginPage'>
      <div className='LoginWrapper'>
        <div className='RightSide'>
          <div style={{ marginTop: '2%' }}>
            {!showForm ? (
              <>
                <h3 id='CreateAccountText'>Create Account</h3>
                <h5 style={{ textAlign: 'left', padding: 0, margin: 0, marginTop: 10, marginBottom: 30 }}>Choose between Full-time or Internship</h5>
                <div className='SignUpButtons'>
                  <div
                    className={`big-button ${activeButton === 'Employer' ? 'active' : ''}`}
                    onClick={() => handleClick('Employer')}
                  >
                    <h3>Employer</h3>
                    <h5>Are you interested in being an employer? Placeholder text placeholder text</h5>
                  </div>
                  <div
                    className={`big-button ${activeButton === 'Freelancer' ? 'active' : ''}`}
                    onClick={() => handleClick('Freelancer')}
                  >
                    <h3>Freelancer</h3>
                    <h5>Are you interested in being a freelancer? Placeholder text placeholder text</h5>
                  </div>
                </div>
                <button style={{ cursor: 'pointer' }} id='handleNextButton' className='next-button' onClick={handleNext}>Next</button>
              </>
            ) : (
              <form onSubmit={signup} id='SignUpForm'>
                <h3>Name</h3>
                <input required type="text" id="fName" name="fName" placeholder='First Name' /><br />
                {activeButton !== 'Employer' && (
                  <>
                    <input required type="text" id="lName" name="lName" placeholder='Last Name' /><br />
                    <h3>Date of Birth</h3>
                    <input required type="date" id="dob" name="dob" placeholder="Enter Date" /><br />
                  </>
                )}

                <h3>Choose a username</h3>
                <input required type="text" id="username" name="username" placeholder='Username' /><br />
                <h3>Email</h3>
                <input required type="email" id="email" name="email" placeholder='Email' /><br />
                {/* TODO  New Data Saved. Also Companies shouldn't have dob or last name.  */}
                {activeButton === 'Employer' && (
                  <>
                    <h3>Company Website</h3>
                    <input required type="text" id="companyWebsite" name="companyName" placeholder='https://www.yourcompany.com' /><br />
                  </>
                )}
                {activeButton === 'Employer' && (
                  <>
                    <h3>Company Industry</h3>
                    {/* <input required type="text" id="companyIndustry" name="companyName" placeholder='Web development, marketing, AI, etc' /><br /> */}
                    <div id='ChooseIndustryDropDown'>
                      <select>
                        <option value="" disabled selected>
                          Choose Industry
                        </option>
                        {options.map((option, index) => (
                          <option key={index} value={option.toLowerCase().replace(/\s+/g, '-')}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {activeButton === 'Employer' && (
                  <>
                    <h3>Company Location</h3>
                    <input required type="text" id="companyLocation" name="companyLocation" placeholder='Location' /><br />
                  </>
                )}
                {/* TODO Next Page for employer starts here */}
                {activeButton === 'Employer' && (
                  <>
                    <h3>Primary Contact Name</h3>
                    <input required type="text" id="companyContactName" name="companyContactName" placeholder='Primary Contact Name' /><br />
                  </>
                )}
                {activeButton === 'Employer' && (
                  <>
                    <h3>Primary Contact Job Title</h3>
                    <input required type="text" id="companyContactTitle" name="companyContactTitle" placeholder='Primary Contact Job Title' /><br />
                  </>
                )}
                {activeButton === 'Employer' && (
                  <>
                    <h3>Primary Contact Email</h3>
                    <input required type="email" id="companyContactEmail" name="companyContactEmail" placeholder='Primary Contact Email' /><br />
                  </>
                )}
                {activeButton === 'Employer' && (
                  <>
                    <h3>Primary Contact Phone Number</h3>
                    <input required type="text" id="companyContactPhone" name="companyContactPhone" placeholder='Primary Contact Phone Number' /><br />
                  </>
                )}
                {/* TO DO  New Data Saved */}

                <h3>Password</h3>
                <input required type="password" id="pass" name="pass" placeholder='Password' onChange={(e) => checkPassStrength(e, setStrength)} />
                <input required type="password" id="pass2" name="pass2" placeholder='Re-Enter Password' /><br />
                {error ? <div style={{ color: 'red', marginTop: '-20px' }}>{error}</div> : <></>}
                {loading ? <div style={{ height: '30px' }}><Loading size={20} /></div> : <input required type="submit" placeholder='Submit' />}
              </form>
            )}
          </div>
          {showForm && (
            <>
              <h3 style={{ fontSize: 12 }}>By creating this account, you agree to our <a target="_thapa" href='TermsCondition'>Terms and Conditions</a></h3>
            </>
          )}
          {!showForm && (
             <div className='LoginCreateAccount'>
                <Link style={{ fontSize: 12, color:'#34AED4', fontWeight:600}} to='/Login'>Have An Account?</Link>
              </div>
          )}
        </div>
        <ImageBatch />
      </div>
    </div>
  );
}

