import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import Home from './pages/Home';
import Login_SignUp from './Login_SignUp';
import './App.css';
import Foras from './pages/Foras';
import Profile from './pages/Profile';
import ApplyForas from './pages/ApplyForas';
import Freelancers from './pages/Freelancers';
import About from './pages/About';
import Login from './Login';
import Notifications from './Notifications';
import ChatPage from './ChatPage';
import AddForas from './pages/AddForas';
import ProfileEmployer from './pages/ProfileEmployer';
import useNavbarAndFooter from './hooks/useNavbarAndFooter';
import useAxiosDefaults from './hooks/useAxiosDefaults';
import { chatSocket } from '.';
import { useUser } from './hooks/useUser';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import TermsConditions from './TermsConditions';
import ContactUs from './ContactUs.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import Applicants from './pages/Applicants.js';
import ProtectedRoute from './pages/components/route/ProtectedRoute.js';
import BillingPage from './pages/AddForasFlow/BillingPage.js';
import Confirmation from './pages/AddForasFlow/Confirmation.js';
import JobStatus from './pages/JobStatus.js';
import AddCredits from './pages/PaymentFlow/AddCredits.js';
import ActiveForsa from './pages/ActiveForsa.js';
import NotFound from './pages/NotFound.js';
import ChatNavbar from './ChatNavbar.js'
function App() {
  // Controlling when navbar and footer are displayed
  const showNavbarAndFooter = useNavbarAndFooter();

  // Setting up axios defaults
  useAxiosDefaults();

  const { loggedIn } = useUser();

  useEffect(() => {
    if (loggedIn && !chatSocket.connected) {
      chatSocket.connect();
    } else if (!loggedIn && chatSocket.connected) {
      chatSocket.disconnect();
    }
  }, [loggedIn]);

  const currentPath = window.location.pathname;

  return (
    <>
      {showNavbarAndFooter && (
        currentPath === '/ChatPage' || currentPath === '/ContactUs' ? <ChatNavbar /> : <Navbar />
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Home" element={<Home />} />
        <Route path="Foras" element={<Foras />} />
        <Route path="Profile" element={<Profile />} />
        <Route path="Applicants/:id" element={<ProtectedRoute roles={['employer']}><Applicants /></ProtectedRoute>} />
        <Route path="JobStatus/:id" element={<ProtectedRoute roles={['employer']}><JobStatus /></ProtectedRoute>} />
        <Route path="AddCredits" element={<ProtectedRoute roles={['employer']}><AddCredits /></ProtectedRoute>} />
        <Route path="ApplyForas/:id" element={<ApplyForas />} />
        <Route path="ActiveForsa/:id" element={<ActiveForsa />} />
        <Route path="Freelancers" element={<Freelancers />} />
        <Route path="Login_SignUp" element={<Login_SignUp />} />
        <Route path="About" element={<About />} />
        <Route path="Login" element={<Login />} />
        <Route path="Notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
        <Route path="ChatPage" element={<ChatPage />} />
        <Route path="AddForas" element={<AddForas />} />
        <Route path="ForgotPassword" element={<ForgotPassword />} />
        <Route path="ResetPassword" element={<ResetPassword />} />
        <Route path="TermsCondition" element={<TermsConditions />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="BillingPage" element={<BillingPage />} />
        <Route path="Confirmation" element={<Confirmation />} />
        <Route path="*" element={<NotFound />} /> {/* Non Existent Pages */}
      </Routes>
      {showNavbarAndFooter && <Footer />}
    </>
  );
}

export default App;
