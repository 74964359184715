import { useEffect, useRef, useState } from "react";
import Loading from "../Loading";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { maxUserFileSize, validImageTypes, validResumeTypes } from "../../../config";
import BackgroundProfile from '../../stylesheets/imgs/BackgroundProfile.png'
import ProfileJobs from './ProfileJobs'
import { Rating } from 'react-simple-star-rating'
import ProfileSection from "./ProfileSection";
import ProfileImage from "../ProfileImage.js";
import { FaPaperclip } from 'react-icons/fa';


const Bio = ({ BioText, user, isCurUser }) => {
    const [bio, setBio] = useState(BioText)
    const [editing, setEditing] = useState(false)
    const [updating, setUpdating] = useState(false)

    function handleBioChange(e) {
        setBio(e.target.value)
    }

    function handleSaveBio(e) {
        setEditing(false)
        setUpdating(true)
        axios.put('/users/update', {
            user: user._id,
            bio
        })
            .catch(error => console.error(error))
            .finally(() => setUpdating(false))
    }
    return (
        <div className='Experience' style={{ backgroundColor: 'transparent', width: '90%', marginLeft: '2.5%', marginTop: '1%', display: 'flex' }}>
            {bio.length > 0 && <div className='EnterBio'>
                <h3 style={{ color: 'grey', fontStyle: 'italic', fontSize: '1.5svh' }}>
                    " {bio} "</h3>
            </div>}
            {updating ? <Loading /> : !editing ?
                isCurUser && <button id="isEditing" onClick={() => setEditing(true)}>
                    Edit Bio</button>
                : <>
                    <input
                        type="text"
                        placeholder="Enter your bio"
                        value={bio}
                        onChange={handleBioChange}
                        style={{ width: '60%', marginRight: '2.5%', height: '100%', borderRadius: 10, borderWidth: .5, padding: 10, fontStyle: 'italic' }}

                    />
                    <button style={{ width: "15%" }} onClick={handleSaveBio}>Save Bio</button>
                </>
            }
        </div>
    )
}

function Specialization({ category, user, isCurUser }) {
    const [cat, setCat] = useState(category)
    const [editing, setEditing] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(user.category?._id)
    const [activeTags, setActiveTags] = useState()

    const selectedCategoryTags = categories.find((option) => option._id === selectedCategory)?.tags || [];

    function handleCategoryEdit(e) {
        setEditing(true)

        axios.get('/categories', { params: { enabled: true } })
            .then(result => {
                setCategories(result.data?.categories)
                setActiveTags(prev => {
                    if (prev) return prev;

                    const tagObj = {}
                    result.data?.find((option) => option._id === selectedCategory)?.tags?.forEach((e, i) => tagObj[e] = i)
                    const res = user.tags?.map(e => tagObj[e]) || []
                    return res
                })
            })
            .catch(error => console.error(error))
    }

    function genOptionList() {

        return categories?.map((option) => {
            return <option key={option._id} value={option._id} >
                {option.name}
            </option>
        })
    }

    function handleCategorySave(e) {
        setUpdating(true)
        setEditing(false)
        axios.put('/users/update', {
            user: user._id,
            category: selectedCategory,
            tags: activeTags.map(e => selectedCategoryTags[e])
        })
            .catch(error => console.error(error))
            .finally(() => {
                setUpdating(false)
                setCat(categories.find(c => c._id == selectedCategory)?.name)
            })
    }


    const generateTags = () => {
        return selectedCategoryTags.map((button, index) => (
            <button
                key={index}
                id={activeTags.includes(index) ? 'activeSpecial' : ''}
                onClick={(event) => handleClickTag(event, index)}
            >
                {button}
            </button>
        ))
    }
    const handleClickTag = (event, index) => {
        event.preventDefault()
        if (activeTags.includes(index)) {
            setActiveTags(activeTags.filter((item) => item !== index));
        } else {
            setActiveTags([...activeTags, index]);
        }
    };

    const handleChangeCategory = (event) => {
        setSelectedCategory(event.target.value)
        setActiveTags([])
    }


    return (
        <div style={{ paddingTop: 5, display: 'flex' }}>
            {cat?.length > 0 && <h3 style={{ marginTop: '-.5%', fontSize: '1.5svh', fontWeight: 400 }}>Specialist in {cat}</h3>}
            {updating ? <Loading /> : !editing ?
                isCurUser && <button id="isEditingSpecial" onClick={handleCategoryEdit}>Edit</button>
                :
                <div style={{position:'absolute', left:'33%', top:'15%'}}> 
                    <select value={selectedCategory} onChange={handleChangeCategory} style={{ borderRadius: 5, padding: 5, marginBottom: 10 }}>
                        <option value={""}>Select Specialization</option>
                            {genOptionList()}
                    </select>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <div id="TagRow" style={{ marginBottom: 10 }}>
                        {generateTags()}
                    </div>
                    <button id="SaveSpecialization" style={{ width: "20svh" }} onClick={handleCategorySave}>
                        Save Specialization</button>
                </div>
                </div>
            }
        </div>
    )

}

export default function EmployeeProfile({ employee, curUser }) {
    const isCurUser = curUser.id === employee._id
    const resumeInputRef = useRef(null)
    const imageInputRef = useRef(null)
    const [uploading, setUploading] = useState(false)
    const [history, setHistory] = useState(undefined)
    const navigate = useNavigate()
    const location = useLocation()
    const [imageProgress, setImageProgress] = useState()
    const [imageUploadedTime, setImageUploadedTime] = useState()
    const [activeTab, setActiveTab] = useState('Personal');

    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
    };


    function genProfileHistory() {
        if (!history) {
            return <Loading />
        }

        return history.map(e => <ProfileJobs key={e._id} jobTitle={e.title} jobDesc={e.description} />)
    }

    function handleFileChoice(event) {
        event.preventDefault();
        resumeInputRef.current?.click()
    }

    function handleImageChoice(event) {
        if (!isCurUser) {
            return;
        }
        event.preventDefault()
        imageInputRef.current?.click()
    }

    /**
     * 
     * @param {"image" | "cv"} docType 
     * @returns 
     */
    const handleUploadFile = (docType) => function (event) {
        event.preventDefault();

        // Validating size
        const file = event.target.files[0]
        if (!file) {
            return;
        }

        if (file.size > maxUserFileSize) {
            console.error(`File too large. Max size is ${maxUserFileSize / (1024 * 1024)}MB`);
            event.target.value = null
            return;
        }

        const validTypes = docType === 'cv' ? validResumeTypes : validImageTypes

        // Validating file type
        if (!validTypes.split(',').includes(file.type)) {
            console.error("Invalid file type. Allowed types are: " + validTypes);
            event.target.value = null
            return;
        }

        // Creating form for axios

        if (!curUser.loggedIn) {
            navigate('/Login', { state: { from: location.pathname + location.search } })
            return;
        }

        const formData = new FormData();
        formData.append('user', curUser.id);
        formData.append('type', docType);
        formData.append('file', file)

        setUploading(true)
        setImageProgress(0)
        axios.post('/users/files/upload', formData, {
            onUploadProgress: e => {
                if (docType !== 'image') {
                    return;
                }
                setImageProgress(e.progress)
            }
        })
            .then(() => {
                if (docType !== 'image') {
                    return;
                }
                setImageUploadedTime(Date.now())
            })
            .catch(error => console.error(error))
            .finally(() => setUploading(false))

    }

    function handleDownloadResume(event) {
        event.preventDefault();

        if (!curUser.loggedIn) {
            navigate('/Login', { state: { from: location.pathname + location.search } })
            return;
        }

        axios.post(
            '/users/files/download',
            { requester: curUser.id, user: employee?._id, type: 'cv' },
            { responseType: 'blob', headers: { 'x-http-method-override': 'GET' }, onDownloadProgress: e => console.log(e) }
        )
            .then(result => {
                if (!result.data || result.data.length < 1) {
                    console.error("CV Unavailable");
                }

                const objURL = URL.createObjectURL(result.data)
                const a = document.createElement('a')
                a.href = objURL
                a.download = (`${employee.fullName} Resume.pdf`)
                document.body.appendChild(a)
                a.click()

                URL.revokeObjectURL(objURL)
                document.body.removeChild(a)
            })
            .catch(error => console.error(error))
    }

    useEffect(() => {
        axios.get(`/jobs/history/${employee._id}?limit=3`)
            .then(result => {
                setHistory(result.data)
            })
            .catch(error => {
                setHistory([])
                console.log(error);
            })
    }, [employee])

    return (
        <div className='ProfilePage' style={{minHeight:'100svh'}}>
            <>
                <div>
                    <div className='ProfileTag'>
                        {/* <img className='ProfileImg' src={ProfileImg} /> */}
                        <ProfileImage uploadedTime={imageUploadedTime} progress={imageProgress} className='ProfileImg' id={employee._id} imageURL={employee.imageURL} style={{ cursor: isCurUser ? 'pointer' : 'inherit' }} onClick={handleImageChoice} />
                        <div className='ProfileDesc'>
                            <h2 style={{ color: 'black', marginBottom: 0, fontSize: '2.5svh' }}>{employee.fullName}</h2>
                            <Specialization
                                category={employee.category?.name}
                                user={employee}
                                isCurUser={isCurUser}
                            />
                            <h3 style={{ margin: 0, fontSize: '1.25svh' }}>@{employee.username}</h3>
                            {/* <br /> */}
                            {/* <Rating initialValue={employee.rating?.average} readonly={true} allowFraction={true} /> */}
                        </div>
                        <div className='applybutton'>
                            {isCurUser ? <></> : <center><Link to={`/ChatPage?id=${employee._id}`}><button>Contact</button></Link></center>}
                        </div>
                    </div>
                </div>

                <Bio
                    BioText={employee.bio}
                    user={employee}
                    isCurUser={isCurUser}
                />

                <div style={{ width: '25%', display: 'flex', flexDirection: 'row', userSelect: 'none', alignItems: 'center', marginTop: '1%', marginLeft: '5%', textAlign: 'center', }}>
                    <a
                        id='SwitcherProfile'
                        onClick={() => handleTabSwitch('Personal')}
                        style={{
                            backgroundColor: activeTab === 'Personal' ? '#34AED4' : 'white',
                            color: activeTab === 'Personal' ? 'white' : '#34AED4',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s, color 0.3s',
                            borderRadius: 10,
                            padding: '3.5%',
                            fontSize: 14,
                            width: '15vh',
                            fontWeight: 600
                        }}
                    >
                        Personal
                    </a>
                    {isCurUser && (
                        <a
                            id='SwitcherProfile'
                            onClick={() => handleTabSwitch('Applied Jobs')}
                            style={{
                                backgroundColor: activeTab === 'Applied Jobs' ? '#34AED4' : 'white',
                                color: activeTab === 'Applied Jobs' ? 'white' : '#34AED4',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s, color 0.3s',
                                borderRadius: 10,
                                padding: '3.5%',
                                fontSize: 14,
                                width: '15vh',
                                fontWeight: 600

                            }}
                        >
                            Applied Jobs
                        </a>
                    )}
                    <a
                        id='SwitcherProfile'
                        onClick={() => handleTabSwitch('Forsa History')}
                        style={{
                            backgroundColor: activeTab === 'Forsa History' ? '#34AED4' : 'white',
                            color: activeTab === 'Forsa History' ? 'white' : '#34AED4',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s, color 0.3s',
                            borderRadius: 10,
                            padding: '3.5%',
                            fontSize: 14,
                            width: '15vh',
                            fontWeight: 600

                        }}
                    >
                        Forsa History
                    </a>
                </div>
                {activeTab === 'Personal' && (
                    <div className='AllProfile'>
                        <div className='rightside'>
                            <ProfileSection
                                name={'experience'}
                                header={'Experiences'}
                                user={employee}
                                isCurUser={isCurUser}
                            />

                            <ProfileSection
                                name={'education'}
                                header={"Education"}
                                user={employee}
                                isCurUser={isCurUser}
                            />
                            <ProfileSection
                                name={'certificates'}
                                header={'Certificates'}
                                user={employee}
                                isCurUser={isCurUser}
                            />
                            <ProfileSection
                                name={'skills'}
                                header={'Skills'}
                                user={employee}
                                isCurUser={isCurUser}
                            />
                        </div>
                    </div>
                )}
                {/* TODO Integrate applied jobs here. */}
                {activeTab === 'Applied Jobs' && isCurUser && (
                    <div className='AllProfile'>
                        <h3>TBI (To Be Integrated)</h3>
                    </div>
                )}

                {activeTab === 'Forsa History' && (
                    <div style={{display:'flex', flexDirection:'row', gap:'2.5%', flexWrap:'wrap', marginLeft:'7.5%', marginTop:'2.5%', minHeight:'75svh'}}>
                        {genProfileHistory()}
                    </div>
                )}


                <div className='leftSide'>

                    <h3 style={{}}>Attachments</h3>
                    <div className='applybutton' style={{ marginBottom: "2%" }}>
                        {isCurUser &&
                            <>
                                {employee.wallet && <div>
                                    <h3 style={{}}>Wallet: {employee.wallet.credits} credits</h3>
                                </div>}
                                <input disabled={uploading} type='file' ref={resumeInputRef} style={{ display: 'none' }} accept={validResumeTypes} onInput={handleUploadFile('cv')} />
                                <input disabled={uploading} type='file' ref={imageInputRef} style={{ display: 'none' }} accept={validImageTypes} onInput={handleUploadFile('image')} />
                                {uploading ? <Loading /> : <button id="FileUpload" style={{ width: 175 }} onClick={handleFileChoice}>Upload Resume ⊕</button>}
                            </>
                        }
                    </div>
                    <div className='applybutton' style={{ marginTop: '5%', }}>
                        <a id="DownloadResume" style={{
                            width: 300, backgroundColor: '#d9d9d990', color: 'black', fontWeight: 300, textAlign: 'left', padding: '5%',
                            display: 'flex', alignItems: 'center', borderRadius: 20, cursor: 'pointer'
                        }} onClick={handleDownloadResume}>
                            <FaPaperclip color="black" size={20} />
                            <h3 id="DownloadText" style={{ fontSize: '1.75svh', marginLeft: '2.5%', fontStyle: 'italic' }}>
                                {employee.fullName}'s Resume.pdf
                            </h3>
                        </a>

                    </div>

                </div>
            </>
        </div>
    )
}